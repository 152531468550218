import * as Vue from 'vue'
const routes = [
  {
    name: 'tutor.index',
    path: '/tutor',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/Index.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.score.old',
    path: '/tutor-score',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/Score.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.score',
    path: '/tutor/score',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/Score.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.form',
    path: '/tutor/form',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/Form.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.courses.class',
    path: '/tutor/courses/class',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/course/Class.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.1v1.courses',
    path: '/tutor/1v1/courses',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/course/1v1.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.1v1.courses.records',
    path: '/tutor/1v1/courses/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/course/Records.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.courses.live-form',
    path: '/tutor/courses/:id/live-form',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/course/LiveForm.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.level',
    path: '/tutor/level',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/Level.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.salary',
    path: '/tutor/salary',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/salary/Index.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.salary.confrontation',
    path: '/tutor/salary/confrontation',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/tutor/salary/Confrontation.vue')
    ),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.salary.records',
    path: '/tutor/salary/:id/records',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/salary/Record.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.salary.record',
    path: '/tutor/salary/records/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/salary/Detail.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.withdraw',
    path: '/tutor/withdraw',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/withdraw/Index.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.withdraw.records',
    path: '/tutor/withdraw/records',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/withdraw/Record.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.withdraw.record',
    path: '/tutor/withdraw/records/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/withdraw/Detail.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.point',
    path: '/tutor/point',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/point/List.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.point.show',
    path: '/tutor/point/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/point/Detail.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.modify',
    path: '/tutor/modify',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/Modify.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.teaching',
    path: '/tutor/teaching',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/teaching/Show.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.teaching.form',
    path: '/tutor/teaching/form',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/teaching/Form.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.1v1.form',
    path: '/tutor/1v1/form',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/1v1/Form.vue'))
  },
  {
    name: 'tutor.influence',
    path: '/tutor/influence',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/influence/Index.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.influence.detail',
    path: '/tutor/influence/detail',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/influence/Detail.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.type-tax',
    path: '/tutor/type-tax',
    component: Vue.defineAsyncComponent(() => import('@/pages/tutor/type-tax/index.vue'))
  },
  {
    name: 'tutor.evaluation-after-class',
    path: '/tutor/evaluation-after-class',
    component: () => import('@/pages/tutor/evaluation-after-class.vue')
  },
  {
    name: 'tutor.income.records',
    path: '/tutor/income/records',
    component: () => import('@/pages/tutor/income/index.vue'),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.income.records.search',
    path: '/tutor/income/records-search',
    component: () => import('@/pages/tutor/income/search.vue'),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.1v1.interview',
    path: '/tutor/1v1/interview',
    component: () => import('@/pages/tutor/1v1/interview/index.vue'),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.1v1.interview.feedback',
    path: '/tutor/1v1/interview/feedback/:id',
    component: () => import('@/pages/tutor/1v1/interview/feedback-detail.vue'),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.course.selected.confirm',
    path: '/tutor/course-confirm',
    component: () => import('@/pages/tutor/course-selected-confirm.vue'),
    meta: {
      auth: true
    }
  },
  {
    name: 'tutor.course.records',
    path: '/tutor/records',
    component: () => import('@/pages/tutor/course-records.vue'),
    meta: {
      auth: true
    }
  }
]

export default routes
