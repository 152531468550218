import http from '@/utils/http'

export const getTutorScore = (params: any) => http.get('tutor-score', { params })

export const postTutorFinance = (params: any) => http.post('tutor-finances/store-finance-message', params)

export const getTutorCenter = () => http.get('tutor-center')

// Apply
export const getTutorApply = () => http.get('tutors/audit')

export const getTutorCoursesApply = () => http.get('tutor-courses-audit')

export const getTutorWillSelects = () => http.get('will-selects')

export const postTutorApply = (params: any) => http.post('tutors', params)

export const updateTutor = (params: any) => http.post('tutors_apply', params)

export const updateTutorCourses = (params: any) => http.post('tutors/courses', params)

export const markAuditRead = (params: any) => http.post('tutors/audit-alert', params)

export const setCollectQuestionnaire = (id: number) => http.post(`set-collect-questionnaires/${id}`)

// 1v1 Tutor Apply
export const get1v1Apply = () => http.get('1v1/tutors')

export const update1v1Apply = (params: any) => http.post('1v1/tutors', params)

// Salary (已废弃，已废弃)
// export const getTutorSalaries = (params) => http.get('tutor-salary-grants', { params })

// export const getTutorSalaryRecordsById = (id) => http.get(`tutor-salary-grants/${id}/records`)

// export const getTutorSalaryRecordById = (id) => http.get(`tutor-salary-records/${id}`)

// Point
export const getTutorPoints = (params: any) => http.get('tutor-point-records', { params })

export const getTutorPointById = (id: number) => http.get(`tutor-point-records/${id}`)

// Notice
export const getTutorNotice = () => http.get('tutor-notice')

// 获取Tutor日报
export const getTutorEvaluations = (params: any) => http.get('get-evaluations', { params })
// 获取Tutor 日报更多评价详情
export const getEvaluationDetails = (params: any) => http.get('get-evaluation-details', { params })
// Teach Courses
export const getTeachCourses = (params: any) => http.get('v3/tutor-tutorial-classes', { params })

// Tutor Teach Subjects
export const getTeachSubjects = (id: number) => http.get(`v3/tutor-product-courses/${id}`)
// 上课主题
export const getTeachThemes = () => http.get<string[]>('v3/tutor-product-theme')
// 1v1 获取导师可教技能
export const getTeachSkills = (id: number) => http.get(`v3/tutor-product-skills/${id}`)

export const postTeachLive = (params: any) => http.post('v3/tutor-product/create-class', params)

// 1v1 Teach Courses
export const get1v1TeachCourses = (params: any) => http.get('v3/tutor-product-classes', { params })

export const get1v1CourseRecordsById = (id: number, params: any) => http.get(`v3/tutor-product-classes/${id}`, { params })

export const updateLiveRecord = (params: any) => http.post('v3/tutor-product/update-class', params)

export const top1v1TutorCourse = (id: number) => http.get(`v3/tutor-classes-top/${id}`)

// 讲师阅读行为准则
export const tutorReadCriterion = () => http.patch('users/store_tutor_read_criterion')

// Tutor 收入
// 获取收入薪资类型
export const getTutorSalaryTypes = () => http.get('tutor-salary-type')

// Tutor 薪资记录列表
export const getTutorSalaryGrants = (params: any) => http.get('tutor-salary-grants', { params })

// Tutor 薪资记录详情
export const getTutorSalaryGrantRecordsById = (id: number) => http.get(`tutor-salary-grants/${id}/records`)

// Tutor 薪资流水详情
export const getTutorSalaryRecordById = (id: number) => http.get(`tutor-salary-records/${id}`)

// Tutor 提现薪资记录列表
export const getTutorWithdrawList = (params: any) => http.get('withdraw/tutor-salary-grants', { params })

// Tutor 提现记录列表
export const getTutorWithdrawRecords = (params: any) => http.get('tutor-salary-withdrawal', { params })

// Tutor 提现明细详情
export const getTutorSalaryDetailById = (id: number) => http.get(`withdraw/tutor-salary-detail/${id}`)

// Tutor 提交提现申请
export const postTutorSalaryWithdraw = (params: any) => http.post('tutor-salary-withdrawal', params)

// Tutor 提现失败弹窗
export const getTutorSalaryFail = () => http.get('withdraw/tutor-salary/fail')

// 查询个税代扣代缴接口
export const queryTaxationTutorSalaryWithdrawal = (ids: any) => {
  return http.post('tutor-salary-withdrawal/taxation', {
    ids
  })
}

// 劳务报酬结算明细
export const getTutorSalaryWithdrawalDetail = (ids: any) => {
  return http.post('tutor-salary-withdrawal/taxation/detail', {
    ids
  })
}

// 劳务报酬结算历史明细
export const getTutorSalaryWithdrawalTaxationDetail = (id: number) => {
  return http.post(`tutor-salary-withdrawal/${id}/taxation/detail`)
}

/**
 *
 * 获取用户1v1产品的商品信息
 *
 * @param userId 学生id
 * @returns
 */
export const getUser1v1Info = (userId: string | number) => {
  return http.get(`product/user-1v1-info/${userId}`)
}

/**
 *
 * 老师填写学习反馈
 *
 * @param data.id 直播间id
 * @param data.mastery_situation 学员课堂掌握情况
 * @param data.homework 作业
 * @returns
 */
export const postTutorProductFeedback = (data: {
  id: number | string
  mastery_situation: string
  homework: string
}) => {
  return http.post('v3/tutor-product/feedback', data)
}

/**
 * 获取教师节年度数据
 *
 * @param tutorId
 * @returns
 */
export const getTutorAnnualStatistics = (tutorId: string) => {
  return http.get('get/tutor-detail', {
    params: {
      tutor_id: tutorId
    }
  })
}

/**
 *
 * 导师收入-流水记录接口
 *
 * @param params
 * @returns
 */
export const getTutorSalaryFinancialRecords = (params?: {
  pageSize?: number // 每次请求返回记录数量
  only_show_last_year_data?: number // 只展示最近一年的数据（只有手机端需要传这个）  默认0，如果是手机端就传1
  search?: string // 可以搜索 章节标题，课程标题，科目代码。
  time?: string // 时间搜索（月） 格式 ：2023-07
  page?: number // 页码
}) => {
  return http.get<{
    current_page: number
    is_functional_currency: boolean
    last_page: number
    total: number
    list: {
      title: string
      original_amount: string
      rate: string
      tutor_salary_audit_id: number
      id: number
      type_label: string
      amount: string
      created_at: string
      currency: string
      rate_currency: string
    }[]
  }>('tutor-salary-financial-records', {
    params
  })
}

/**
 *
 * 1v1面试官面试日程
 *
 * @param {string} params.begin_at 开始时间
 * @param {string} params.end_at 结束时间
 * @returns
 */
export const get1v1TutorInterviewSchedule = (params: {
  begin_at: string
  end_at: string
}) => {
  return http.get<{
    pending_interview_count: number
    change_count: number
    pending_feedback_count: number
    schedule: {
      date: string
      week: string
      record_list: {
        id: number
        meeting_link: string
        name: string
        english_name: string
        time: string
        begin_at: string
        end_at: string
        change: number // 当=1时 为面试变更
      }[]
    }[]
  }>('v3/interview-schedule', {
    params
  })
}

/**
 *
 * 面试变更知晓
 *
 * @param id 日程id
 * @returns
 */
export const postConfirmInterviewChange = (id: number | string) => {
  return http.post('v3/interview-change-confirm', {
    id
  })
}

/**
 *
 * 1v1面试反馈列表
 *
 * @param {number} params.status 3待反馈 4已反馈
 * @returns
 */
export const get1v1InterviewFeedbacks = (params: {
  status: number
  page?: number
  per_page?: number
}) => {
  return http.get<{
    data: any[]
    links: {
      first: string
      last: string
      next: string
      prev: string
    }[]
    meta: any
  }>('v3/interview-feedback-list', {
    params
  })
}

/**
 *
 * 1v1面试提交或者修改反馈
 *
 * @param data
 * @returns
 */
export const post1v1InterviewFeedback = (data: {
  id: string | number // 日程id
  result: number // 面试结果 1面试通过 2面试失败 3建议二面
  grade: number // 面试等级  1S 2A 3B
  trial_lecture_content: string
  using_skill: string
  concerns: string
}) => {
  return http.post('v3/submit-interview-feedback', data)
}

/**
 *
 * 1v1 面试反馈详情
 *
 * @param id
 * @returns
 */
export const get1v1InterviewFeedbackDetail = (id: string | number) => {
  return http.get(`v3/interview-feedback-detail/${id}`)
}

/**
 *
 * 根据id和key获取1v1tutor候选库资料
 *
 * @param params.id 入职链接的id参数
 * @param params.key 入职链接的key参数
 * @returns
 */
export const get1v1TutorCandidate = (params: {
  id: string | number
  key: string| number
}) => {
  return http.get('1v1-tutor-candidate', {
    params
  })
}

/**
 *
 * 候选库资料跟用户绑定（登录后调用）
 *
 * @param {number} id 候选库编号id
 * @returns
 */
export const updateUser1v1CandidateTutor = (id: number | string) => {
  return http.get(`v3/update-candidate-tutor/${id}`)
}

/**
 * 选课信息确认页面
 *
 * @param id
 * @returns
 */
export const getTutorCourseConfirmInfo = (id: any) => {
  return http.get('courses-confirm', {
    params: {
      survey_link_record_id: id
    }
  })
}

/**
 * 选课信息确认
 *
 * @param params
 * @returns
 */
export const postTutorCourseConfirm = (params: any) => {
  return http.post('courses-confirm', params)
}

/**
 * 选课记录
 *
 * @param params
 * @returns
 */
export const getTutorCourseRecords = (params: any) => {
  return http.get('survey-submissions', { params })
}

/**
 * 撤销投递/重新投递
 *
 * @param id
 * @param params
 * @returns
 */
export const postTutorCourseRecordStatus = (id: any, params: any) => {
  return http.post(`survey-submissions/${id}`, params)
}
